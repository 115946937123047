<template>
    <div>
        <page-title
            :heading="$t('settings.lang_users')"
            :permissionAdd="this.$store.getters['permissions/checkPermission']('user_create')"
            show-add-button
            url-add="/settings/basicSettings/createUsers"
            :subheading="$t('settings.lang_users')"
            :icon="icon"
        ></page-title>
        <div class="app-main__inner">
            <SearchUsers></SearchUsers>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import SearchUsers from "../../../../components/settings/basic/users/SearchUsers";

    export default {
        components: {
            PageTitle,
            SearchUsers
        },
        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        }),
        mounted() {
            //console.log(this.$store.getters['permissions/checkPermission']('user_create'));
        }
    }
</script>

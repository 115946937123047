<template>

    <div class="mb-3 card">
        <div>

            <!-- Table -->
            <Datatable :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.USER" :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       :permissionDelete="true/*this.$store.getters['permissions/checkPermission']('user_delete')*/"
                       :permissionEdit="true /*this.$store.getters['permissions/checkPermission']('user_edit')*/"
                       @deleteEntries="entriesDelete"
                       @deleteEntry="entryDelete"
                       @editEntry="entryEdit"
                       excel-file-name="Benutzer"
                       ref="userDatatable"
                       show-delete-buttons
                       show-edit-buttons
                       v-show="showTable"

            />


        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../../config'
    import {Events} from "../../../../plugins/events";
    import Datatable from "../../../datatable/Datatable";
    import mixin from "../../../../mixins/KeyboardMixIns";

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                allergenAdd: "",
                loading: false,
                showTable: true,
                showCreate: false,
                showUpdate: false,
                tab: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                    },
                    {text: this.$t("customers.lang_cust_prename"), value: 'prename'},
                    {text: this.$t("customers.lang_cust_name"), value: 'lastname'},
                    {text: this.$t("customers.lang_cust_birth"), value: 'birth'},
                    {text: this.$t("generic.lang_emailAdresse"), value: 'email'},
                    {text: this.$t("generic.lang_group"), value: 'group'},
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: "id",
                    },
                    {
                        label: this.$t("customers.lang_cust_prename"),
                        field: "prename",
                    },
                    {
                        label: this.$t("customers.lang_cust_name"),
                        field: "lastname",
                    },
                    {
                        label: this.$t("customers.lang_cust_birth"),
                        field: "birth",
                    },
                    {
                        label: this.$t("generic.lang_emailAdresse"),
                        field: "email",
                    },
                    {
                        label: this.$t("generic.lang_group"),
                        field: "group",
                    },
                ],
            }
        },

        watch: {
            tab: function () {
                if (this.tab === 1) {
                    this.showTable = true;
                    this.showUpdate = false;
                }
            }
        },

        methods: {
            deleteData: function (idsToDelete = []) {
                this.$swal({
                    title: this.$t('settings.lang_user_delete'),
                    text: this.$t('settings.lang_user_delete_confirm'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.SETTINGS.USER.DELETE, {
                            userID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('settings.lang_userDeleted'),
                                    color: "success"
                                });

                                this.resetData();

                                this.$refs.userDatatable.getDataFromApi();
                                this.$refs.userDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            resetData: function () {
                this.showCreate = false;
                this.showUpdate = false;
                this.showTable = true;
                this.id = null;
                this.text = "";
            },
            showList: function () {
                this.resetData();
                this.tab = 0;
            },
            entryEdit(entry) {
                this.id = entry.id;
                this.text = entry.name;

                this.$router.push({name: 'settings.basicSettings.editUsers', params: {id: parseInt(this.id)}})
            },
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            }
        },
    }
</script>